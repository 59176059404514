<template>
    <main class="c-app-content is-sidebar-tiny">
        <div class="row">
            <div class="col-12" style="justify-content: space-around; flex-direction: row; display: flex;">
                <div class="c-btn-mobile js-btn-mobile mt-2"><i class="fa fa-bars"></i> Mở rộng menu</div>
            </div>
            <div class="col-lg-2 c-sidebar-main">
                <div class="c-sidebar-menu">
                    <ul>
                        <li>
                            <label>Danh sách</label>
                            <ul>
                                <router-link to="/manager/setting/patient" v-slot="{href, isExactActive}">
                                    <li v-if="hasIndicator('patient')" :class="[isExactActive ? 'active' : '']"><a :href="href">Danh sách người khuyết tật nhận hỗ trợ từ dự án</a></li>
                                </router-link>
                                <router-link to="/manager/setting/patient-other" v-slot="{href, isExactActive}">
                                    <li v-if="permissions('patient-other_index')"  :class="[isExactActive ? 'active' : '']"><a :href="href">Danh sách người khuyết tật nhận được hỗ trợ từ các dự án khác</a></li>
                                </router-link>
                                <router-link to="/manager/setting/edu-patient" v-slot="{href, isExactActive}">
                                    <li  v-if="hasIndicator('edu-patient')" :class="[isExactActive ? 'active' : '']"><a :href="href">Danh sách cán bộ được dự án đào tạo</a></li>
                                </router-link>
                                
                            </ul>
                        </li>
                        <li>
                            <label>Danh sách người khuyết tật nhận được các can thiệp</label>
                            <ul>
                                <router-link to="/manager/setting/patient-phcn" v-slot="{href, isExactActive}">
                                    <li v-if="hasIndicator('patient-phcn')" :class="[isExactActive ? 'active' : '']"><a :href="href">Phục hồi chức năng</a></li>
                                </router-link>
                                <router-link to="/manager/setting/patient-care" v-slot="{href, isExactActive}">
                                    <li v-if="hasIndicator('patient-care')" :class="[isExactActive ? 'active' : '']"><a :href="href">Chăm sóc y tế</a></li>
                                </router-link>
                                <router-link to="/manager/setting/patient-mentality" v-slot="{href, isExactActive}">
                                    <li v-if="hasIndicator('patient-mentality')" :class="[isExactActive ? 'active' : '']"><a :href="href">Can thiệp tâm lý</a></li>
                                </router-link>
                                <router-link to="/manager/setting/patient-integrate" v-slot="{href, isExactActive}">
                                    <li v-if="hasIndicator('patient-integrate')" :class="[isExactActive ? 'active' : '']"><a :href="href">Hỗ trợ hòa nhập</a></li>
                                </router-link>
                                <router-link v-if="isPrime() || isTW() || isUsaid()" to="/manager/setting/patient-life" v-slot="{href, isExactActive}">
                                    <li v-if="hasIndicator('patient-life')" :class="[isExactActive ? 'active' : '']"><a :href="href">Kết quả đo lường chất lượng sống</a></li>
                                </router-link>
                            </ul>
                        </li>
                        <li>
                            <label>Danh sách người thân/người chăm sóc nhận được các hỗ trợ</label>
                            <ul>
                                <router-link to="/manager/setting/patient-relative" v-slot="{href, isExactActive}">
                                    <li v-if="hasIndicator('patient-relative')" :class="[isExactActive ? 'active' : '']"><a :href="href">Can thiệp tâm lý</a></li>
                                </router-link>
                                <router-link to="/manager/setting/patient-relative-train" v-slot="{href, isExactActive}">
                                    <li v-if="hasIndicator('patient-relative-train')" :class="[isExactActive ? 'active' : '']"><a :href="href">Đào tạo người thân </a></li>
                                </router-link>
                            </ul>
                        </li>
                        <li v-if="(hasIndicator('org') || hasIndicator('org-cso'))"  >
                            <label>Danh sách cơ sở, tổ chức</label>
                            <ul>
                                <router-link to="/manager/setting/org-multiple" v-slot="{href, isExactActive}">
                                    <li  v-if="hasIndicator('org-multiple')" :class="[isExactActive ? 'active' : '']"><a :href="href">Cơ sở PHCN đa chuyên ngành</a></li>
                                </router-link>
                                <router-link to="/manager/setting/org" v-slot="{href, isExactActive}">
                                    <li  v-if="hasIndicator('org')" :class="[isExactActive ? 'active' : '']"><a :href="href">Tổ chức được hỗ trợ nâng cao năng lực</a></li>
                                </router-link>
                            </ul>
                        </li>
                        <li v-if="(hasIndicator('org') || hasIndicator('org-cso'))"  >
                            <label>Danh sách hội nhóm, câu lạc bộ người khuyết tật</label>
                            <ul>
                                <router-link to="/manager/setting/org-cso" v-slot="{href, isExactActive}">
                                    <li  v-if="hasIndicator('org-cso')" :class="[isExactActive ? 'active' : '']"><a :href="href">Tham gia vận động chính sách</a></li>
                                </router-link>
                            </ul>
                        </li>
                        <li >
                            <label>Danh sách cán bộ được đào tạo</label>
                            <ul>
                                <router-link to="/manager/setting/edu-phcn" v-slot="{href, isExactActive}">
                                    <li  v-if="hasIndicator('edu-phcn')" :class="[isExactActive ? 'active' : '']"><a :href="href">Đào tạo về phục hồi chức năng</a></li>
                                </router-link>
                                <router-link to="/manager/setting/edu-care" v-slot="{href, isExactActive}">
                                    <li  v-if="hasIndicator('edu-care')" :class="[isExactActive ? 'active' : '']"><a :href="href">Đào tạo về chăm sóc</a></li>
                                </router-link>
                                <router-link to="/manager/setting/edu-gender" v-slot="{href, isExactActive}">
                                    <li  v-if="hasIndicator('edu-gender')" :class="[isExactActive ? 'active' : '']"><a :href="href">Đào tạo về giới</a></li>
                                </router-link>
                                <router-link to="/manager/setting/edu-other" v-slot="{href, isExactActive}">
                                    <li  v-if="hasIndicator('edu-other')" :class="[isExactActive ? 'active' : '']"><a :href="href">Đào tạo chủ đề khác</a></li>
                                </router-link>
                            </ul>
                        </li>
                        <li >
                            <label>Danh sách hoạt động sự kiện</label>
                            <ul>
                                <router-link to="/manager/setting/edu-event" v-slot="{href, isExactActive}">
                                    <li v-if="hasIndicator('edu-event')" :class="[isExactActive ? 'active' : '']"><a :href="href">Người khuyết tật tham gia hoạt động, sự kiện</a></li>
                                </router-link>
                            </ul>
                        </li>
                        <li v-if="isSub() && hasIndicator('indicator-manual')" >
                            <label>Chỉ số (IPs)</label>
                            <ul>
                                <router-link to="/manager/setting/indicator-manual" v-slot="{href, isExactActive}">
                                    <li  v-if="hasIndicator('indicator-manual')" :class="[isExactActive ? 'active' : '']">
                                        <a :href="href">
                                            Chỉ số nhập trực tiếp 
                                            <span class="text-danger small" >({{Object.keys(indicators).length}})</span>
                                        </a>
                                    </li>
                                </router-link>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-10">
                <router-view></router-view>
            </div>
        </div>
    </main>
</template>

<script>
    import $ from 'jquery';
    import role from '@/mixins/Role';

    export default {
        mixins: [role],
        data: function () {
            return {
                client: this.$store.state.client,
                clients: [],
                kpis: [],
                indicators: {},
            };
        },
        methods: {

        },
        mounted: async function () {
            this.indicators = {};
            for (let code in this.$params.indicator) {
                let indicator = this.$params.indicator[code];
                if (!indicator.manual) {
                    continue;
                }
                this.indicators[code] = indicator;
            }
            this.$bus.$on('reloadKpi', this.load);

            //js expand menu setting
            $('.js-btn-mobile').on('click', function (e) {
                e.preventDefault();
                var sidebar = $('.c-sidebar-main');
                if (sidebar.hasClass('active')) {
                    sidebar.removeClass('active').slideUp();
                } else {
                    sidebar.addClass('active').slideDown();
                }
            });

            //close setting menu in mobile
            $('.c-sidebar-menu a').on('click', function (e) {
                var sidebar = $('.c-sidebar-main');
                var viewportGlobal = $(window).width();
                if (e.target == this && viewportGlobal < 768) {
                    sidebar.removeClass('active').slideUp();
                }
            });
        },
        beforeDestroy: function () {
            this.$bus.$off('reloadKpi');
        },
    }
</script>